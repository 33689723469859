<template>
    <div id="section" :style="sectionStyle">
        <div id="content" :style="contentStyle">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        sectionStyle() {
            return {
                padding: this.$vuetify.breakpoint.forPC ? '100px 0' : '30px 30px'
            }
        },
        contentStyle() {
            return {
                width: this.$vuetify.breakpoint.forPC ? '1000px' : '100%'
            }
        }
    }
}
</script>
<style scoped>
#content {
    width: 1000px;    
    margin: 0 auto;
    z-index: 2;
}
</style>